(function ($) {

	function Equalize() {

		if (!$.fn.equalize) {
			return;
		}

		$('[data-equalheight]').each(function (index, element) {
			var $element = $(element);
			var type = $element.data('equalheight') || 'height';
			$element.equalize({
				equalize: type,
				reset: true
			});
		});

	}

	$(function() {

		Equalize();

		// Make sure we re-equalize when images are loaded.
		$('img').on('load', function() {
			Equalize();
		});

	});

})(jQuery);
