(function ($) {


	function Select(element) {

		var $element = $(element);
		var self = this;
		self.$element = $element;
		self.$wrapper = $element.wrap('<span class="fancy-select-wrapper"></span>').parents('.fancy-select-wrapper').first();
		$element.removeClass('fancy');
		var selector = 'option:first';
		if ($('option:selected', $element).val() !== '') {
			selector = 'option:selected';
		}
		var title = $(selector, $element).text();
		var optionClasses = $(selector, $element).attr('class') || '';

		// If the select is disabled, add that to the wrapper.
		if ($element.is(':disabled')) {
			self.$wrapper.addClass('disabled');
		}

		// Add the underlay.
		var $label = $('<span class="fancy-select-label"></span>');
		$label.addClass(optionClasses);
		$label.text(title);
		$label.css('min-width', $element.width());
		$element.before($label);

		// Add styles.
		this.$wrapper.css('position', 'relative');
		this.$element.css({
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			top: 0,
			opacity: 0,
			'-webkit-appearance': 'none',
			'-moz-appearance': 'none',
			'-o-appearance': 'none',
			'-ms-appearance': 'none',
			'z-index': 3,
			outline: 'none',
			width: '100%'
		});

		// Attach events.
		$element.on('change', function(e) {
			self.onSelection();
		});
		$element.on('focus', function(e) {
			self.setState('focused');
		});
		$element.on('blur', function(e) {
			self.removeState('focused');
		});

	}

	Select.prototype.setState = function(state) {
		this.$wrapper.addClass('state-' + state);
	};

	Select.prototype.removeState = function(state) {
		this.$wrapper.removeClass('state-' + state);
	};

	Select.prototype.onSelection = function() {
		val = $('option:selected', this.$element).text();
		this.$element.siblings('.fancy-select-label').text(val);
	};

	Select.prototype.setValue = function(value) {
		this.$element.find('option').filter(function() {
			return $.trim($(this).val()) === value;
		}).prop('selected', true);
		this.onSelection();
	};

	window.SelectInit = function() {
		$('select[data-fancy], select.fancy').each(function (index, element) {
			if (!$(this).data('fancy-select')) {
				var result = new Select(this);
				$(this).data('fancy-select', result);
			}
		});
	};

	if (Drupal.ajax !== undefined) {
		Drupal.ajax.prototype.commands.reFancy = SelectInit;
	}

	$(SelectInit);

})(jQuery);
