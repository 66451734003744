(function ($) {
    $(function() {

        $('.slider-sync-big').each(function (index, element) {
            var $nav = $(element).siblings('.slider-sync-thumbs');
            $(element).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: $nav
            });
            $nav.slick({
                slidesToScroll: 1,
                asNavFor: $(element),
                variableWidth: true,
                infinite: false,
                dots: false,
                arrows: false,
                centerMode: true,
                focusOnSelect: true
            });
        });

    });
})(jQuery);
