// REFERENCES:
// http://jsfiddle.net/AhC87/2/
// https://github.com/zurb/responsive-tables

jQuery(document).ready(function($) {
    var switched = false;
    var theTable = $('table.responsive');
    var stickyTable = $('table.sticky-header');
    var body = $('body');
    var breakpoint = 1024;

    // The intitialization function
    var updateTables = function() {
        if (($(window).width() < breakpoint) && !switched ){
            switched = true;
            theTable.each(function(i, element) {
                splitTable($(element));
                mobileTableDrag();
            });
            stickyTable.hide();
            return true;
        }
        else if (switched && ($(window).width() > breakpoint)) {
            switched = false;
            theTable.each(function(i, element) {
                unsplitTable($(element));
            });
            stickyTable.show();
            resetStickyHeader(stickyTable);
        }
    };

    // Where the magic is fired up.
    $(window).load(function(){updateTables();});
    $(window).on("redraw",function(){switched=false; updateTables();});
    $(window).resize(function(){updateTables();});

    function splitTable(original)
    {
        original.wrap("<div class='table-wrapper' />");

        var copy = original.clone();
        copy.find("td:not(:first-child), th:not(:first-child)").css("display", "none");
        copy.removeClass("responsive");

        original.closest(".table-wrapper").append(copy);
        copy.wrap("<div class='pinned' />");
        original.wrap("<div class='scrollable' />");

        setCellHeights(original, copy);
    }

    function unsplitTable(original) {
        original.closest(".table-wrapper").find(".pinned").remove();
        original.unwrap();
        original.unwrap();
    }

    function setCellHeights(original, copy) {
        var tr = original.find('tr'),
            tr_copy = copy.find('tr'),
            heights = [];

        tr.each(function (index) {
            var self = $(this),
                tx = self.find('th, td');

            tx.each(function () {
                var height = $(this).outerHeight(true);
                heights[index] = heights[index] || 0;
                if (height > heights[index]) heights[index] = height;
            });

        });

        tr_copy.each(function (index) {
            $(this).height(heights[index]);
        });
    }

    // Enables to ability for desktop users to drag and move the mobile table.
    function mobileTableDrag(){
        var calculation = function()
        {
            var element = $('.scrollable'),
                x,y,top,left,down;

            element.mousedown(function(e){
                body.css('cursor', 'pointer');
                e.preventDefault();
                down=true;
                x=e.pageX;
                y=e.pageY;
                top=$(this).scrollTop();
                left=$(this).scrollLeft();
            });

            body.mousemove(function(e){
                if(down){var newX=e.pageX;element.scrollLeft(left-newX+x);}
            });

            body.mouseup(function(e){down=false; body.css('cursor', 'default');});
        }
        calculation();
    }

    // Add sticky table header responsiveness
    function resetStickyHeader(table){
        var resizeTableWidth = function(){
            table.css('width', theTable.width());
        }
        resizeTableWidth()
        $(window).resize(resizeTableWidth);
    }


});
