jQuery(document).foundation();

jQuery(window).load(function () {
	if (jQuery('.charts-maps').length) {
		jQuery('.charts-maps').foundation('getHeights', function (heights) {
			jQuery('.charts-maps').foundation('applyHeight', heights);
		});
	}
});

jQuery(document).ready(function($){
	var headerNav = $('#header-nav'),
		headerNavItems = $('#header-nav .wrapper .region').find('.block'),
		mainNav = $('#main-nav .navigation');

	function createLowerNav(mainNav){

		hideHeaderNav();

		$('.navigation-lower').remove(); // Make sure old nav does not creep up.

		// Start building a new navigation piece.
		mainNav.after('<div class="navigation-lower" />');
		$('.navigation-lower').append('<ul class="menu" />');
		headerNavItems.each(function(){
			var items = $(this).html();
			$('.navigation-lower').find('ul.menu').append('<li class="leaf">'+ items +'</li>');
		});
	}

	function showHeaderNav(){
		// Remove the lower navigation that was for mobile.
		$('.navigation-lower').remove();

		// Return header nav back to normal.
		headerNav.show();
	}

	function hideHeaderNav(){headerNav.hide();}

	var lowerNavInit = function(size){
		if(Foundation.MediaQuery.atLeast('large')){
			showHeaderNav();
		}else{
			createLowerNav(mainNav);
		}
	};

	lowerNavInit();
	$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize){
		lowerNavInit();
	});
});

jQuery(document).ready(function($) {
	$('.map-item').click(function () {
		var $el = $(this);

		$('.modal img').attr('src', $el.data('framed-image'));
		$('.modal').show();
		$('.overlay').show();
	});

	$('.map-x').click(function () {
		$('.modal').hide();
		$('.overlay').hide();
	});

	$('.overlay').click(function () {
		$('.modal').hide();
		$('.overlay').hide();
	});
});

jQuery(document).ready(function($) {
	$(document).on('click', 'button.fa', function () {
		$(this).children('input').trigger('click');
	})
});
